import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import { AppBar, Box, Button, Drawer, IconButton, Toolbar, Typography } from '@mui/material'
import Collapse from '@mui/material/Collapse'
import MUIList from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import { styled } from '@mui/material/styles'
import { NextRouter } from 'next/router'
import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react'
import get from 'lodash/get'
import clsx from 'clsx'
import MenuIcon from '@mui/icons-material/Menu'
import Image from 'next/image'
import theme from '@theme'
import { SLButton, SLInteriorButton } from '@commons/modules/Button/Button'
import Logo from '@commons/modules/Logo'
import { HeroSubscriptionStatus } from '@module/PaymentOptions/Stripe/Plan'
import { CurrentUser } from '@type/user'
import ROUTES from '@constant/Routes'
import Link from '@commons/modules/Link'
import { reqLogoutUser } from '@api/api'
import { PAYMENT_MENU, DRAWER_WIDTH } from '@constant/index'
import DrawerUser from '@commons/modules/DrawerUser/DrawerUser'
import { Home } from '@constant/Routes/Routes'
import { endtheRigConfig } from 'configs/endtherig'
import { getCurrentDomain, Project } from 'configs'
import { MenuItem } from '../types/types'

const List = styled(MUIList)`
  & .MuiListItemIcon-root {
    color: ${theme.palette.text.primary};
  }
  & .MuiTypography-root {
    font-size: ${theme.typography.body2};
  }
  & .Mui-selected,
  & .Mui-selected .MuiListItemIcon-root {
    color: ${theme.palette.primary.main};
  }
  & .MuiListItemButton-SubMenu {
    padding-left: 56px;
    white-space: nowrap;
  }
  & .MuiListItemButton-SubMenu-Item {
    padding-left: 66px;
  }
`

const CustomListIcon = styled(ListItemIcon)`
  min-width: 40px;
`

const LogoContainer = styled('div')`
  padding: 20px;
  padding-bottom: 10px;
  margin-bottom: 0;
`
const ButtonContainer = styled(Box)(() => ({
  background: theme.palette.neutralVariant100,
  width: '100%',
  height: '64px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}))

const CustomListItemButton = styled(ListItemButton)`
  padding-top: ${theme.spacing(0.5)};
  padding-bottom: ${theme.spacing(0.5)};
`
const SubListItem = styled(ListItemButton)`
  padding-left: ${theme.spacing(7)};
  padding-top: ${theme.spacing(0.5)};
  padding-bottom: ${theme.spacing(0.5)};
  & .MuiTypography-root {
    font-size: 12px;
  }
`

export const StyleLogoImage = styled(Image)(() => ({
  maxWidth: '150px',
  height: '100%',
  width: '100%',
  minWidth: '110px',
  padding: '15px',
}))
type Props = {
  menuItems: MenuItem[]
  userMenuItems?: MenuItem[]
  currentUser?: CurrentUser
  logoutUser?: () => void
  homeRoute?: string
  showCreatorCredit?: boolean
  protectedRoutes: string[]
  router: NextRouter
}

const PermanentDrawer = ({
  userMenuItems,
  menuItems,
  currentUser,
  logoutUser,
  homeRoute = '/',
  showCreatorCredit = true,
  protectedRoutes = [],
  router,
}: Props) => {
  const [mobileOpen, setMobileOpen] = React.useState(false)
  const [isClosing, setIsClosing] = React.useState(false)
  const [openMenu, setOpenMenu] = useState<string>()
  const [prevRoute, setPrevRoute] = useState<string>(homeRoute)
  const [activeRoute, setActiveRoute] = useState<MenuItem>()
  const [selectedUserMenu, setSelectedUserMenu] = useState<MenuItem>()
  const handleDrawerClose = () => {
    setIsClosing(true)
    setMobileOpen(false)
  }

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false)
  }
  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen)
    }
  }

  const handleRouteComplete = useCallback(() => {
    setIsClosing(false)
    setMobileOpen(false)
  }, [])

  useEffect(() => {
    router.events.on('routeChangeComplete', handleRouteComplete)
    return () => {
      router.events.off('routeChangeComplete', handleRouteComplete)
    }
  }, [router.events, handleRouteComplete])

  const handleKeyPress = (event) => {
    const isInputField = event.target.tagName.toLowerCase() === 'input'
    if (!isInputField) {
      const key = event.key.toUpperCase()
      if (['[', ']'].includes(key)) setIsClosing(!isClosing)
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', handleKeyPress)
    return () => {
      document.removeEventListener('keydown', handleKeyPress)
    }
  })

  const handleUserMenuSelect = (menuItem: MenuItem) => {
    setSelectedUserMenu(menuItem)
  }
  useEffect(() => {
    let currentSelectedMenu = null
    let found = false
    if (router.route === homeRoute) {
      setOpenMenu('Play Center')
      found = true
    }
    const allMenuItems = currentUser ? [...menuItems, ...userMenuItems] : menuItems
    let currMenu = null
    let lastNonSubMenu: MenuItem = null
    const setSubItemMenu = (menuItem: MenuItem) => {
      menuItem.subItems.forEach((subItem) => {
        if (subItem.path === router.route) {
          setPrevRoute(menuItem.path)
          setOpenMenu(subItem.name)
          setActiveRoute(subItem)
          found = true
          currentSelectedMenu = subItem.newPageMenu
            ? subItem
            : selectedUserMenu ?? lastNonSubMenu ?? currMenu
          // check if router.route begins with subItem.path
        } else if (router.route.startsWith(subItem.path) && subItem.subItems) {
          // keepParentMenuSelected is set for handling menu selection
          // in some cases such as in Our Players under Admin, we need to be under Admin
          // at the same time Our players needs to be selected while we are getting into nested pages on Our Players
          // it works well initially whitout the below condition but the behaviour is different on refresh as it does have subitems
          // in such case keepParentMenuSelected helps us identify if the parent menu needs to be the selected user menu
          // that way with help of this entity, we don't have to add extra cases for specific subitems where the main menu item of it's parents needs to be shown
          if (subItem?.keepParentMenuSelected) {
            setSelectedUserMenu(menuItem)
          }
          if (!subItem.showSubItems) {
            lastNonSubMenu = subItem
          }
          currMenu = subItem
          setSubItemMenu(subItem)
        }
      })
    }
    allMenuItems?.forEach((menuItem) => {
      if (menuItem.path === router.route) {
        const hasSubItems = Boolean(menuItem?.subItems && menuItem.subItems.length > 0)

        // for main menu items (such as leaderboard, admin, setting and so on), those that has subitems and showSubItems is set to false,
        // they open a new page with new menu items. if showSubItems was set to true, it would form a drodown menu with subitems on same menu page live we have for transitions inside story engine
        // so before, our such main menus that would open in new page like admin, settings, story-engine, and the back button would lead them directly to homepage
        // this logic here sets leaderboard as previous route for such menu items

        if (hasSubItems && !menuItem?.showSubItems && getCurrentDomain() === Project.END_THE_RIG) {
          setPrevRoute('/leaderboard')
        } else {
          setPrevRoute(homeRoute)
        }
        setActiveRoute(menuItem)
        found = true
        currentSelectedMenu = menuItem
        if (menuItem.subItems) {
          menuItem.subItems.forEach((subItem) => {
            if (subItem.path === router.route) {
              setOpenMenu(menuItem.name)
              found = true
              currentSelectedMenu = menuItem
            }
          })
        } else {
          setOpenMenu(menuItem.name)
        }
        // check if router.route begins with menuItem.path
      } else if (router.route.startsWith(menuItem.path) && menuItem.subItems) {
        if (!menuItem.showSubItems) {
          lastNonSubMenu = menuItem
        }
        currMenu = menuItem
        setSubItemMenu(menuItem)
        // below is a custom logic to show Leave Internet Apps (Fixes) while navigating to it from Admin
        // since the route for Internet Apps is /fixes and doesn't fall under /admin, we have to use a custom logic here
      } else if (router.route === ROUTES.Fixes && menuItem.name === 'Admin') {
        // this helps point to the correct menu items
        setSubItemMenu(menuItem)
        // this handles the later routes and active menus
        setSelectedUserMenu(menuItem)
      }
    })

    if (selectedUserMenu?.path !== currentSelectedMenu?.path) {
      if (found && currentSelectedMenu && !currentSelectedMenu.showSubItems) {
        setSelectedUserMenu(currentSelectedMenu)
      } else if (
        !currentSelectedMenu?.newPage &&
        !router.route.includes(ROUTES.RiggedEconomyRiggedAreaEdit) &&
        !router.route.includes(ROUTES.Fixes) // to exclude clearing selected user menu in case of Fixes to keep it selected in main menu
      ) {
        setSelectedUserMenu(null)
      }
    }
  }, [
    router.route,
    homeRoute,
    menuItems,
    selectedUserMenu,
    currentUser,
    userMenuItems,
    activeRoute?.subItems,
  ])

  const checkMenuStatus = (path) => {
    if (router.route === ROUTES.Home && path === ROUTES.PlayCenter) return true
    if (path === get(router, 'route', '') || get(router, 'route', '').includes(path)) return true
    return false
  }
  const handleHeaderLogoClick = () => {
    router.push(Home)
  }
  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${DRAWER_WIDTH}px)` },
          ml: { sm: `${DRAWER_WIDTH}px` },
          background: theme.palette.neutralVariant100,
          display: { sm: 'none' },
        }}
      >
        <Toolbar>
          <Box
            className="header-logo"
            sx={{ cursor: 'pointer', flexGrow: 1 }}
            onClick={handleHeaderLogoClick}
          >
            <StyleLogoImage height={100} width={100} src={endtheRigConfig.logo} alt="HOE Icon" />
          </Box>
          <IconButton
            color="default"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box component="nav" sx={{ width: { sm: DRAWER_WIDTH }, flexShrink: { sm: 0 } }}>
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onTransitionEnd={handleDrawerTransitionEnd}
          onClose={handleDrawerClose}
          ModalProps={{
            keepMounted: true,
          }}
        >
          <LogoContainer>
            <Link href={homeRoute} passHref>
              <Logo priority />
            </Link>
          </LogoContainer>

          {selectedUserMenu && selectedUserMenu.subItems ? (
            <SubMenuItems
              prevRoute={prevRoute}
              activeRoute={activeRoute}
              selectedUserMenu={selectedUserMenu}
              router={router}
              openMenu={openMenu}
              currentUser={currentUser}
            />
          ) : (
            <MainMenuItems
              menuItems={currentUser ? [...menuItems, ...userMenuItems] : menuItems}
              currentUser={currentUser}
              protectedRoutes={protectedRoutes}
              setOpenMenu={setOpenMenu}
              router={router}
              openMenu={openMenu}
              checkMenuStatus={checkMenuStatus}
            />
          )}

          <Box>
            <DrawerUser
              onSelect={handleUserMenuSelect}
              userMenuItems={userMenuItems}
              selectedMenuName={selectedUserMenu ? selectedUserMenu.name : undefined}
              currentUser={currentUser}
              logoutUser={async () => {
                setOpenMenu(null)
                setSelectedUserMenu(null)
                const jwt = localStorage.getItem('jwt')
                await reqLogoutUser(jwt)
                logoutUser()
              }}
              showCreatorCredit={showCreatorCredit}
            />
          </Box>
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: DRAWER_WIDTH },
          }}
          open
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <Box sx={{ flex: 0 }}>
              <LogoContainer>
                <Link href={homeRoute} passHref>
                  <Logo priority />
                </Link>
              </LogoContainer>
            </Box>

            <Box sx={{ flex: 1, overflowY: 'auto' }}>
              {selectedUserMenu && selectedUserMenu.subItems ? (
                <SubMenuItems
                  prevRoute={prevRoute}
                  activeRoute={activeRoute}
                  selectedUserMenu={selectedUserMenu}
                  router={router}
                  openMenu={openMenu}
                  currentUser={currentUser}
                />
              ) : (
                <MainMenuItems
                  menuItems={currentUser ? [...menuItems, ...userMenuItems] : menuItems}
                  currentUser={currentUser}
                  protectedRoutes={protectedRoutes}
                  setOpenMenu={setOpenMenu}
                  router={router}
                  openMenu={openMenu}
                  checkMenuStatus={checkMenuStatus}
                />
              )}
            </Box>

            <Box sx={{ flex: 0 }}>
              <DrawerUser
                onSelect={handleUserMenuSelect}
                userMenuItems={userMenuItems}
                selectedMenuName={selectedUserMenu ? selectedUserMenu.name : undefined}
                currentUser={currentUser}
                logoutUser={async () => {
                  setOpenMenu(null)
                  setSelectedUserMenu(null)
                  const jwt = localStorage.getItem('jwt')
                  await reqLogoutUser(jwt)
                  logoutUser()
                }}
                showCreatorCredit={showCreatorCredit}
              />
            </Box>
          </Box>
        </Drawer>
      </Box>
    </Box>
  )
}

export default PermanentDrawer

// main menus
type MainMenuItemsProps = {
  menuItems: MenuItem[]
  protectedRoutes: string[]
  router: NextRouter
  openMenu: string
  setOpenMenu: Function
  currentUser: CurrentUser
  checkMenuStatus: Function
}

const MainMenuItems = ({
  menuItems,
  openMenu,
  router,
  protectedRoutes,
  setOpenMenu,
  currentUser,
  checkMenuStatus,
}: MainMenuItemsProps) => (
  <List sx={{ flex: 1, marginBottom: '10px' }}>
    {
      /* eslint-disable no-nested-ternary */
      menuItems.map((menuItem) =>
        !menuItem.path ? (
          <ListItemText primary={menuItem.name} sx={{ px: '16px', py: '4px' }} />
        ) : (
            protectedRoutes.includes(menuItem.path) && !currentUser
              ? null
              : menuItem.path === ROUTES.Payment &&
                !currentUser.subscriptions
                  ?.map((subscription) => subscription?.status !== HeroSubscriptionStatus.Delete)
                  ?.includes(true) &&
                PAYMENT_MENU
          ) ? (
          <ButtonContainer key={menuItem.name}>
            <Link href={menuItem.path || ''} passHref>
              <SLButton variant="contained" onClick={() => setOpenMenu(menuItem.name)}>
                <SLInteriorButton>GET PREMIUM</SLInteriorButton>
              </SLButton>
            </Link>
          </ButtonContainer>
        ) : (!currentUser?.isAdmin && menuItem.admin) || menuItem.hideMenu ? null : (
          <li key={menuItem.name}>
            <Link href={menuItem.path || ''} passHref>
              <CustomListItemButton
                disableRipple
                selected={
                  router.route === ROUTES.Home
                    ? menuItem.name === 'Congress Members'
                    : checkMenuStatus(menuItem.path)
                }
                onClick={() => {
                  if (openMenu === menuItem.name && menuItem.showSubItems) {
                    setOpenMenu('')
                  } else {
                    setOpenMenu(menuItem.name)
                  }
                }}
                className={checkMenuStatus(menuItem.path) ? 'active-sidebar-button' : ''}
              >
                {menuItem.icon && <CustomListIcon>{menuItem.icon}</CustomListIcon>}

                <ListItemText primary={menuItem.name} sx={{ fontWeight: 'bold' }} />
                {menuItem.subItems && menuItem.showSubItems ? (
                  <Button
                    sx={{
                      color: 'inherit',
                      p: 0,
                      minWidth: '40px',
                    }}
                  >
                    {openMenu === menuItem.name ? <ExpandLess /> : <ExpandMore />}
                  </Button>
                ) : null}
              </CustomListItemButton>
            </Link>
            {menuItem.subItems && menuItem.showSubItems && (
              <Collapse in={openMenu === menuItem.name} timeout="auto" unmountOnExit>
                <List disablePadding>
                  {menuItem.subItems.map((subItem) => (
                    <Link href={subItem.path} passHref key={subItem.path}>
                      <SubListItem disableRipple selected={router.route === subItem.path}>
                        <ListItemText sx={{ fontSize: 14 }} primary={subItem.name} />
                      </SubListItem>
                    </Link>
                  ))}
                </List>
              </Collapse>
            )}
          </li>
        ),
      )
    }
  </List>
)

// sub menus
type SubMenuItemsProps = {
  prevRoute?: string
  selectedUserMenu?: MenuItem
  activeRoute?: MenuItem
  router: NextRouter
  openMenu: string
  subItems?: MenuItem[]
  currentUser?: CurrentUser
}

const SubMenuItems = ({
  prevRoute,
  selectedUserMenu,
  activeRoute,
  router,
  openMenu,
  currentUser,
}: SubMenuItemsProps) => {
  // moved these state here form SubMenuSubItems so as to control them from the Back button click here.
  // need to reset this while clicking the back button so as to clear the selection
  const [selectedSubMenuPath, setSelectedSubMenuPath] = useState('')
  return (
    <List
      sx={{
        flex: 1,
        marginBottom: '10px',
      }}
    >
      <Link
        href={router.pathname === '/story-engine' && currentUser?.isAdmin ? '/admin' : prevRoute}
        passHref
      >
        <CustomListItemButton
          sx={() => ({
            border: `solid 1px ${theme.palette.background.cardBorder}`,
            borderLeftWidth: 0,
            borderRightWidth: 0,
            pt: 1,
            pb: 1,
            mb: '3px',
          })}
          onClick={() => setSelectedSubMenuPath('')}
          disableRipple
        >
          <CustomListIcon>
            <ArrowBackIcon
              sx={() => ({
                color: theme.palette.neutralVariant50,
              })}
            />
          </CustomListIcon>
          <Typography
            variant="body1"
            sx={() => ({
              color: theme.palette.neutralVariant50,
            })}
          >
            {`Leave ${activeRoute ? activeRoute.name : selectedUserMenu.name}`}
          </Typography>
        </CustomListItemButton>
      </Link>
      <SubMenuSubItems
        router={router}
        key={selectedUserMenu.path}
        openMenu={openMenu}
        subItems={selectedUserMenu.subItems}
        selectedSubMenuPath={selectedSubMenuPath}
        setSelectedSubMenuPath={setSelectedSubMenuPath}
      />
    </List>
  )
}

// sub menu sub items
type SubMenuSubItemsProps = {
  router: NextRouter
  openMenu: string
  subItems?: MenuItem[]
  isSubMenuSubItem?: boolean
  setSelectedSubMenuPath: Dispatch<SetStateAction<string>>
  selectedSubMenuPath: string
}
const SubMenuSubItems = ({
  subItems,
  router,
  openMenu,
  isSubMenuSubItem,
  selectedSubMenuPath,
  setSelectedSubMenuPath,
}: SubMenuSubItemsProps) => {
  const handleMenuClick = (domain: string, path: string) => {
    if (domain) {
      window.open(`${domain}/${path}`, '_blank')
    }
  }
  const [expanded, setExpanded] = useState(false)

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const routeResult = () => {
    let { route } = router
    Object.entries(router.query).forEach(([k, v]) => {
      route = route.replace(`[${k}]`, String(v))
    })
    return route
  }

  useEffect(() => {
    // this is to handle cases where we are navigated to some submenu from another submenu's detail page.
    // for example, we can navigate to Missions via Chapters under AI
    // sicne we are navigating via detail page in such case, our selectedSubMenuPath doesn't change as we haven't clicked any menu
    // as a result, both menus were  being selected initially
    // this logic clears the selectedSubMenuPath in such cases so as to keep the right menu selected
    if (selectedSubMenuPath !== '' && !router.route.startsWith(selectedSubMenuPath)) {
      setSelectedSubMenuPath('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.pathname])

  return (
    <>
      {subItems
        .filter((menuItem) => menuItem.name !== 'Surfing Sections')
        .map((menuItem) => {
          if (menuItem.hideMenu) return null

          if (!menuItem.path)
            return <ListItemText primary={menuItem.name} sx={{ px: '16px', py: '4px' }} />
          return (
            <li key={menuItem.name}>
              <Link href={menuItem.path || ''} passHref>
                <CustomListItemButton
                  disableRipple
                  selected={router.route.startsWith(menuItem.path)}
                  className={clsx({
                    'active-sidebar-button Mui-selected': menuItem.path === selectedSubMenuPath, // this covers the persistence of menu selection in case of detail pages as well while navigating from the list page
                    'MuiListItemButton-SubMenu': isSubMenuSubItem,
                  })}
                  onClick={() => {
                    handleMenuClick(menuItem.domain, menuItem.path)
                    setExpanded(!expanded)
                    setSelectedSubMenuPath(menuItem.path) // adding path here as well as it will be helpful for comparisons with routes as well, as required
                  }}
                >
                  {menuItem.icon && <CustomListIcon>{menuItem.icon}</CustomListIcon>}

                  <ListItemText primary={menuItem.name} />
                  {menuItem.subItems && !menuItem.newPageMenu && menuItem.showSubItems ? (
                    <Button
                      sx={{
                        color: 'inherit',
                        p: 0,
                        minWidth: '40px',
                        fontWeight: selectedSubMenuPath === menuItem.path ? '600' : '700',
                        'min-width': 'unset',
                      }}
                    >
                      {selectedSubMenuPath === menuItem.path && expanded ? (
                        <ExpandLess />
                      ) : (
                        <ExpandMore />
                      )}
                    </Button>
                  ) : null}
                </CustomListItemButton>
              </Link>
              {menuItem.subItems && !menuItem.newPageMenu && (
                <Collapse
                  in={selectedSubMenuPath === menuItem.path && expanded}
                  timeout="auto"
                  unmountOnExit
                >
                  <List disablePadding>
                    {menuItem.subItems.map((subItem) => {
                      if (subItem.hideMenu) return null
                      if (subItem.subItems?.length > 0)
                        return (
                          <SubMenuSubItems
                            router={router}
                            openMenu={openMenu}
                            subItems={[subItem]}
                            isSubMenuSubItem
                            key={subItem.path}
                            selectedSubMenuPath={selectedSubMenuPath}
                            setSelectedSubMenuPath={setSelectedSubMenuPath}
                          />
                        )
                      return (
                        <Link href={subItem.path} passHref key={subItem.path}>
                          <SubListItem
                            disableRipple
                            selected={router.route.startsWith(subItem.path)}
                            className={clsx({
                              'MuiListItemButton-SubMenu-Item': isSubMenuSubItem,
                            })}
                          >
                            <ListItemText primary={subItem.name} />
                          </SubListItem>
                        </Link>
                      )
                    })}
                  </List>
                </Collapse>
              )}
            </li>
          )
        })}
    </>
  )
}
