import {
  createTheme,
  CustomThemeOptions,
  Theme as MuiTheme,
  ThemeOptions,
} from '@mui/material/styles'
import { Shadows } from '@mui/material/styles/shadows'
import { CSSProperties } from 'react'
import { PROJECT } from 'configs'

declare module '@emotion/react' {
  export interface Theme extends MuiTheme {}
}

declare module '@mui/material/styles' {
  interface Theme {
    header: {
      fontSize: string | number
      fontWeight: string
    }
    subHeader: {
      fontSize: string | number
      fontWeight: string
    }
  }

  interface TypeBackground {
    nav: string
    nav2: string
    page: string
    tableHead: string
    cardBorder: string
    videoCardBorder: string
    variant50: string
    userAvatar: string
    light: string
    card: string
    lightBlue: string
    darkWhite: string
  }

  interface Palette {
    primaryVariant50?: string
    secondaryMain?: string
    secondayVariant?: string
    backgroundDark?: string
    neutralVariant0?: string
    neutralVariant4D?: string
    neutralVariant10?: string
    neutralVariant20?: string
    neutralVariant30?: string
    neutralVariant40?: string
    neutralVariant50?: string
    neutralVariant60?: string
    neutralVariant70?: string
    neutralVariant80?: string
    neutralVariant90?: string
    neutralVariant95?: string
    neutralVariant99?: string
    neutralVariant100?: string
    neutralVariantc9?: string
    neutralVariant03?: string
    neutralVariant1A?: string
    blackLayer?: string
    gradientStart?: string
    blueBg?: string
    gradientEnd?: string
    profileBackgroundDark?: string
    successVariant10?: string
    activeUserRow?: string
    cameraBackground?: string
    fadedGreen?: string
    greyBorder?: string
    greyTable?: string
    grey16?: string
    grey15?: string
    grey14?: string
    grey13?: string
    grey12?: string
    grey11?: string
    grey10?: string
    grey9?: string
    grey8?: string
    grey7?: string
    grey6?: string
    grey5?: string
    grey4?: string
    grey3?: string
    grey2?: string
    blue?: string
    blue1?: string
    blue2?: string
    blue3?: string
    blue4?: string
    blue5?: string
    blue6?: string
    blue7?: string
    blue8?: string
    blue9?: string
    blue10?: string
    blue11?: string
    blue12?: string
    blue13?: string
    blue14?: string
    blue15?: string
    blue16?: string
    blue17?: string
    blue18?: string
    midnightBlue?: string
    red?: string
    red2?: string
    white1?: string
    yellow?: string
    yellow1?: string
    yellow2?: string
    yellow3?: string
    yellow4?: string
    yellow5?: string
    yellow6?: string
    orange?: string
    orange1?: string
    orange2?: string
    orange3?: string
    orange4?: string
    green?: string
    green1?: string
    green2: string
    green3?: string
    green4?: string
    green5?: string
    green6?: string
    green7?: string
    green8?: string
    green9?: string
    black?: string
    black1?: string
    purple?: string
    purple1?: string
    purple2?: string
    gems: {
      l1: string
      l2: string
      l3: string
      l4: string
      l5: string
      l6: string
      l7: string
    }
    paymentMethod: {
      placeholder: string
      border: string
    }
    gameBadge: {
      border: string
      background: string
      backdrop: string
      dropShadow: string
      innerShadow: string
      inProgress: string
      completed: string
      locked: string
      lockIcon: string
      lockedBorder: string
      boxShadow: string
    }
  }

  // Color paletter for variations of colors
  interface PaletteOptions {
    primaryVariant50?: string
    secondaryMain?: string
    secondayVariant?: string
    backgroundDark?: string
    neutralVariant0?: string
    neutralVariant10?: string
    neutralVariant20?: string
    neutralVariant30?: string
    neutralVariant40?: string
    neutralVariant50?: string
    neutralVariant60?: string
    neutralVariant70?: string
    neutralVariant80?: string
    neutralVariant90?: string
    neutralVariant95?: string
    neutralVariant99?: string
    neutralVariant100?: string
    neutralVariantc9?: string
    blackLayer?: string
    blueBg?: string
    gradientStart?: string
    gradientEnd?: string
    profileBackgroundDark?: string
    successVariant10?: string
    activeUserRow?: string
    cameraBackground?: string
    fadedGreen?: string
    grey14?: string
    grey13?: string
    grey12?: string
    grey11?: string
    grey10?: string
    grey9?: string
    grey8?: string
    grey7?: string
    grey6?: string
    grey5?: string
    grey4?: string
    grey3?: string
    grey2?: string
    blue?: string
    blue1?: string
    blue2?: string
    blue3?: string
    blue4?: string
    blue5?: string
    blue6?: string
    blue7?: string
    blue8?: string
    blue9?: string
    blue10?: string
    blue11?: string
    blue12?: string
    blue13?: string
    blue14?: string
    blue15?: string
    blue16?: string
    blue17?: string
    blue18?: string
    red?: string
    white1?: string
    yellow?: string
    yellow1?: string
    yellow2?: string
    yellow3?: string
    yellow4?: string
    yellow5?: string
    yellow6?: string
    orange?: string
    orange1?: string
    orange2?: string
    orange3?: string
    orange4?: string
    green?: string
    green1?: string
    green2?: string
    green3?: string
    green4?: string
    green5?: string
    green6?: string
    green7?: string
    green8?: string
    green9?: string
    black?: string
    black1?: string
    purple?: string
    purple1?: string
  }

  interface BreakpointOverrides {
    xs: true
    sm: true
    md: true
    lg: true
    xl: true
    xxl: true
    xxxl: true
  }

  // allow configuration using `createTheme`
  interface CustomThemeOptions extends ThemeOptions {
    header?: {
      fontSize?: string | number
      fontWeight?: string
    }
    subHeader?: {
      fontSize?: string | number
      fontWeight?: string
    }
  }

  interface TypographyVariants {
    normalBody: CSSProperties
    boldBody: CSSProperties
    smallBody: CSSProperties
    smallMdBody: CSSProperties
    smallAG: CSSProperties
    title_xxxl: CSSProperties
    subTitle_xxxl?: CSSProperties
    title_lg: CSSProperties
    title_md: CSSProperties
    title_sm: CSSProperties
    title_xs: CSSProperties
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    normalBody?: CSSProperties
    boldBody?: CSSProperties
    smallBody?: CSSProperties
    smallMdBody?: CSSProperties
    smallAG?: CSSProperties
    subGreyText?: CSSProperties
    CardTitleText?: CSSProperties
    title_xxxl?: CSSProperties
    subTitle_xxxl?: CSSProperties
    title_lg?: CSSProperties
    title_md?: CSSProperties
    title_sm?: CSSProperties
    title_xs?: CSSProperties
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    normalBody: true
    boldBody: true
    smallBody: true
    smallMdBody: true
    smallAG: true
    subGreyText: true
    CardTitleText: true
    title_xxxl: true
    subTitle_xxxl: true
    title_lg: true
    title_md: true
    title_sm: true
    title_xs: true
  }
}
const defaultTheme = createTheme({})
const HOE_TYPHOGRAPHY: CustomThemeOptions = {
  typography: {
    title_lg: {
      fontWeight: '600',
      fontSize: '1.25rem',
      lineHeight: '1.67',
      color: '#23272B',
    },
    title_md: {
      fontWeight: '500',
      fontSize: '1rem',
      lineHeight: '1.5',
      color: '#23272B',
    },
    title_sm: {
      fontWeight: '500',
      fontSize: '0.875rem',
      lineHeight: '1.42',
      color: '#23272B',
    },
    title_xs: {
      fontSize: '0.75rem',
      lineHeight: '1.42',
      color: '#23272B',
    },
  },
}
const themeOptions: CustomThemeOptions = {
  shadows: defaultTheme.shadows.map((shadow, i) => {
    if (i === 1 || i === 2) {
      return 'none'
    }
    return shadow
  }) as Shadows,
  palette: {
    primary: {
      contrastText: '#ffffff',
      ...PROJECT.theme.primary,
    },
    secondary: {
      main: '#ffa41f',
      contrastText: '#ffffff',
    },
    error: {
      main: '#F84C4C',
      light: '#f0c3c2',
    },
    warning: {
      main: '#FFB05C',
      light: '#F2C94C',
    },
    success: {
      main: '#27AE60',
    },
    text: {
      primary: '#363555',
      secondary: '#605D62',
    },
    background: {
      nav: '#ffffff',
      nav2: '#FBFBFB',
      page: '#F8F9F9',
      default: '#F8F9F9',
      tableHead: '#F4F4F4',
      cardBorder: '#F4EFF4',
      videoCardBorder: '#464444',
      card: '#F2F2F2',
      userAvatar: 'rgb(255, 87, 34)',
      light: '#C9C5CA',
      lightBlue: '#F3FBFF',
      darkWhite: 'rgba(255, 255, 255, 0.90)',
      tricorderHeader: '#37353B',
    },
    grey: {
      A100: '#F4F4F4',
    },
    gems: {
      level1: '#0fbc5c',
      level2: '#f6cf19',
      level3: '#4c73ea',
      level4: '#d62b2b',
      level5: '#b8b8b8',
      level6: '#f2830e',
      level7: '#1dcaf1',
    },
    paymentMethod: {
      placeholder: '#aab7c4',
      border: '#ccc',
    },
    gameBadge: {
      border: '#60E2FF',
      background: '#004757',
      backdrop: '#110A0C',
      dropShadow: '0px 4px 10px rgba(0, 0, 0, 0.25)',
      innerShadow: 'inset 0px 4px 10px rgba(0, 0, 0, 0.25)',
      inProgress: '#FFB05C',
      completed: '#27AE60',
      locked: '#211613',
      lockIcon: '#C7B8A1',
      lockedBorder: '#110A0C',
      boxShadow: '#4DD4FFC',
    },
    primaryVariant50: '#E4F2FF',
    secondaryMain: '#FFB05C',
    secondayVariant: '#FF9C33',
    backgroundDark: '#0A0A0A',
    neutralVariant0: '#000000',
    neutralVariant4D: '#0000004D',
    neutralVariant10: '#1C1B1F',
    neutralVariant20: '#313033',
    neutralVariant30: '#484649',
    neutralVariant40: '#605D62',
    neutralVariant50: '#787579',
    neutralVariant60: '#939094',
    neutralVariant70: '#AEAAAE',
    neutralVariant80: '#C9C5CA',
    neutralVariant90: '#E6E1E5',
    neutralVariant95: '#F4EFF4',
    neutralVariant99: '#FFFBFE',
    neutralVariant100: '#FFFFFF',
    neutralVariantc9: '#ffffffc9',
    neutralVariant03: '#FFFFFF03',
    neutralVariant1A: '#FFFFFF1A',
    blueBg: '#E5F3FF',
    blackLayer: 'rgba(255, 255, 255, 0.54)',
    profileBackgroundDark: '#1B0632',
    successVariant10: '#007A34',
    activeUserRow: '#FFF7D1',
    cameraBackground: '#D9D9D9',
    fadedGreen: '#6FCF97',
    greyBorder: '#51596D',
    greyTable: '#D1D5DB',
    grey16: '#E0E0E0',
    grey15: '#a6c7eb59',
    grey14: '#37353B',
    grey13: '#C9C3C3',
    grey12: '#737475',
    grey11: '#EFEFEF',
    grey10: '#F2F2F2',
    grey9: '#FAFBFA',
    grey8: '#333333',
    grey7: '#4F4F4F',
    grey6: '#F2F2F2',
    grey5: '#E0E0E0',
    grey4: '#BDBDBD',
    grey3: '#828282',
    grey2: '#6B748B',
    blue: '#3394FF',
    blue1: '#2843E8',
    blue2: '#2D9CDB',
    blue3: '#ECEEFC',
    blue4: '#374770',
    blue5: '#025CC0',
    blue6: '#4AA0FF',
    blue7: '#045DC1',
    blue8: '#56CCF2',
    blue9: '#27507D',
    blue10: '#394266',
    blue11: '#7eaee1',
    blue12: '#D4D9FA',
    blue13: '#8CBCC7',
    blue14: '#263049',
    blue15: '#42537F',
    blue16: '#263049',
    blue17: '#C1C1E1',
    blue18: '#6A7EB2',
    midnightBlue: '#1F283D',
    red: '#EB5757',
    red2: '#FFC9C9',
    yellow: '#FFFFFF4D',
    yellow1: '#FFFFE0',
    yellow2: '#FFDF8C',
    yellow3: '#B98900',
    yellow4: '#B9890036',
    yellow5: '#FDE601',
    yellow6: '#FFFF00',
    white1: '#F0F0F0',
    green: '#32A532',
    green1: '#6FCF97EB',
    green2: '#1D7B44EB',
    green3: '#21D375',
    green4: '#6BBF59',
    green5: '#B2EB98',
    green6: '#27AE603B',
    green7: '#8FFFBE3B',
    green8: '#EAF7F0',
    green9: '#8CBCC7',
    orange: '#F4B221',
    orange1: '#DA7020',
    orange2: '#AC591B',
    orange3: '#854514',
    orange4: '#FFD361',
    black: '#141313CC',
    black1: '#353454',
    purple: '#8C8CC7',
    purple1: '#B15AA4',
    purple2: '#DEBAD9',
    ...PROJECT.theme.buttonGradients,
  },
  typography: {
    h1: {
      fontStyle: 'normal',
      fontWeight: '700',
      fontSize: '64px',
      letterSpacing: '-0.02em',
      color: '#000000',
    },
    h2: {
      fontStyle: 'normal',
      fontWeight: '700',
      fontSize: '40px',
      lineHeight: '47px',
      letterSpacing: '-0.02em',
      color: '#000000',
    },
    h3: {
      fontStyle: 'normal',
      fontWeight: '600',
      fontSize: '32px',
      lineHeight: '38px',
      letterSpacing: '-0.02em',
      color: '#000000',
    },
    h4: {
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '22px',
      lineHeight: '28px',
      letterSpacing: '-0.02em',
      color: '#000000',
    },
    h5: {
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '20px',
      lineHeight: '28px',
      letterSpacing: '-0.02em',
      color: '#000000',
    },
    subtitle1: {
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: '17px',
      color: '#787579',
    },
    normalBody: {
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '16px',
      lineHeight: '140%',
      color: '#000000',
    },
    boldBody: {
      fontStyle: 'normal',
      fontWeight: '700',
      fontSize: '16px',
      lineHeight: '19px',
      color: '#000000',
    },
    smallBody: {
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '16px',
      lineHeight: '140%',
      color: '#333',
    },
    smallMdBody: {
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '16px',
      lineHeight: '140%',
      color: '#000000',
    },
    smallAG: {
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '12px',
      lineHeight: '140%',
      color: '#000000',
      textTransform: 'capitalize',
      textAlign: 'justify',
    },
    subGreyText: {
      fontSize: '18px',
      fontWeight: '500',
      lineHeight: '20px',
      color: '#C9C5CA',
    },
    CardTitleText: {
      fontWeight: '500',
      fontSize: '18px',
      lineHeight: '140%',
      color: '#333333',
    },
    fontFamily: 'var(--font-Poppins)',
    ...HOE_TYPHOGRAPHY.typography,
  },
  header: {
    fontSize: '2rem',
    fontWeight: '600',
  },
  subHeader: {
    fontSize: '1.5rem',
    fontWeight: '600',
  },
  components: {
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: defaultTheme.palette.neutralVariant10,
        },
        asterisk: {
          color: defaultTheme.palette.error.light,
        },
      },
    },
  },
  breakpoints: {
    values: {
      // extra-small
      xs: 0,
      // small
      sm: 600,
      // medium
      md: 900,
      // large
      lg: 1200,
      // extra-large
      xl: 1536,
      // 2k extra-large
      xxl: 2048,
      // 4k extra-large
      xxxl: 3840,
    },
  },
  // TODO: Add typography fontsize here
}

const theme = createTheme(themeOptions)
theme.typography.title_xxxl = {
  fontSize: '3rem',
  [theme.breakpoints.down('sm')]: {
    fontSize: '0.6rem',
  },
  [theme.breakpoints.up('sm')]: {
    fontSize: '0.7rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '0.8rem',
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: '0.9rem',
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: '1rem',
  },
  [theme.breakpoints.up('xxl')]: {
    fontSize: '2rem',
  },
  [theme.breakpoints.up('xxxl')]: {
    fontSize: '3rem',
  },
}

theme.typography.subTitle_xxxl = {
  fontSize: '2rem',
  [theme.breakpoints.down('sm')]: {
    fontSize: '0.6rem',
  },
  [theme.breakpoints.up('sm')]: {
    fontSize: '0.7rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '0.8rem',
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: '0.9rem',
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: '1rem',
  },
  [theme.breakpoints.up('xxl')]: {
    fontSize: '1.5rem',
  },
  [theme.breakpoints.up('xxxl')]: {
    fontSize: '2rem',
  },
}
// Speech Bubble
theme.typography.title_lg = {
  fontSize: '1rem',
  [theme.breakpoints.down('sm')]: {
    fontSize: '0.4rem',
  },
  [theme.breakpoints.up('sm')]: {
    fontSize: '0.5rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '0.7rem',
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: '1.1rem',
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: '1.1rem',
  },
  [theme.breakpoints.up('xxl')]: {
    fontSize: '1.7rem',
  },
  [theme.breakpoints.up('xxxl')]: {
    fontSize: '2.3rem',
  },
}
// Card Title
theme.typography.title_md = {
  fontSize: '1rem',
  [theme.breakpoints.down('sm')]: {
    fontSize: '0.8rem',
  },
  [theme.breakpoints.up('sm')]: {
    fontSize: '0.9rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '1rem',
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: '1.1rem',
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: '1rem',
  },
  [theme.breakpoints.up('xxl')]: {
    fontSize: '1.3rem',
  },
  [theme.breakpoints.up('xxxl')]: {
    fontSize: '1.6rem',
  },
}
// Button Key
theme.typography.title_sm = {
  fontSize: '0.8rem',
  [theme.breakpoints.down('sm')]: {
    fontSize: '0.5rem',
  },
  [theme.breakpoints.up('sm')]: {
    fontSize: '0.6rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '0.7rem',
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: '0.8rem',
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: '0.9rem',
  },
  [theme.breakpoints.up('xxl')]: {
    fontSize: '1.2rem',
  },
  [theme.breakpoints.up('xxxl')]: {
    fontSize: '1.5rem',
  },
}
// Button title
theme.typography.title_xs = {
  fontSize: '1rem',
  [theme.breakpoints.down('sm')]: {
    fontSize: '0.4rem',
  },
  [theme.breakpoints.up('sm')]: {
    fontSize: '0.5rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '0.6rem',
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: '0.7rem',
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: '0.8rem',
  },
  [theme.breakpoints.up('xxl')]: {
    fontSize: '1.3rem',
  },
  [theme.breakpoints.up('xxxl')]: {
    fontSize: '2rem',
  },
}
export default theme
