import { useContext, useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import useAccessToken from '@hook/user/useAccessToken'
import useProtectedRoute from '@hook/common/useProtectedRoute'
import { TRUSTED_DOMAINS_REVERSED } from '@constant/SSO'
import { hasUserSessionExpired } from '@util/helpers'
import { UserContext } from '@context/UserContext'
import ROUTES from '@constant/Routes'
import NetworkStatus from '@commons/modules/NetworkStatus/Index'
import { extractDomains } from 'configs'
import DialogComponent from '@commons/modules/DialogComponent'
import useLoadingOverlay from '@hook/loadingOverlay/useLoadingOverlay'
import useLogout from '@hook/user/useLogout'
import { reqLogoutUser } from '@api/api'
import useCurrentUser from '@hook/user/useCurrentUser'
import GameDialog from '@module/GameDialog/GameDialog'
import { AUTH_ROUTES } from '@constant'

type Props = {
  children: React.ReactNode
}

const commonRoutes = [ROUTES.Home, ROUTES.NewHome, ...AUTH_ROUTES, '/authenticating']

// CHANGE THIS MAP.
export const ALL_ROUTES = {
  riggedhq: Object.values(ROUTES)
    .filter((route) => route.startsWith('/fixes'))
    .map((r) => r.replace(/\/\[[^\]]*\]/g, '')),
  congresshq: Object.values(ROUTES)
    .filter((route) => route.startsWith('/fixes/congress'))
    .map((r) => r.replace(/\/\[[^\]]*\]/g, '')),
  endtherig: [
    ROUTES.Fixes,
    ...Object.values(ROUTES)
      .filter((route) => !route.startsWith('/fixes'))
      .filter((route) => route !== '/')
      .map((r) => r.replace(/\/\[[^\]]*\]/g, '')),
  ],
}

const AppLayout = ({ children }: Props) => {
  const router = useRouter()
  const { loginUser } = useAccessToken()
  const { logoutUser } = useLogout()
  const { setLoadingOverlay } = useLoadingOverlay()
  const { isProtectedRoute } = useProtectedRoute()

  const { changeHasUserStatus } = useContext(UserContext)
  const { currentUser } = useCurrentUser()
  const [openModal, setOpenModal] = useState(false)

  useEffect(() => {
    hasUserSessionExpired().then((isTokenExpired) => {
      if (!isTokenExpired) {
        changeHasUserStatus(true)
      }
    })
  }, [changeHasUserStatus])

  // this logic handles the cases where one user from the game navigates to the web app where another account has been logged in
  // this simply gives them a popup and an option to logout
  useEffect(() => {
    if (router?.query && router?.query?.cuid && currentUser?.id) {
      if (router.query.cuid !== currentUser.id) {
        setOpenModal(true)
      }
    } else {
      setOpenModal(false)
    }
  }, [router.query, currentUser])

  useEffect(() => {
    // if (router.pathname !== ROUTES.Home && router.pathname !== ROUTES.GameDashBoard) {
    //   Beacon('init', process.env.NEXT_PUBLIC_BEACON_ID)
    // }
    if (router.pathname === ROUTES.GameDashBoard || router.pathname === ROUTES.Home) {
      Beacon('destroy')
    }
    //  else if (router.pathname === ROUTES.ContactUs) {
    //   Beacon('init', process.env.NEXT_PUBLIC_BEACON_ID)
    // }
  }, [router.pathname])

  useEffect(() => {
    if (router.isReady) {
      const jwtToken = localStorage.getItem('jwt')
      const { tempToken } = router.query

      if (tempToken !== undefined) {
        loginUser()
        return
      }

      if (
        !jwtToken &&
        window.location.origin !== process.env.NEXT_PUBLIC_HERO_APP_URL &&
        isProtectedRoute
      ) {
        window.location.href = `${process.env.NEXT_PUBLIC_HERO_APP_URL}/signin?origin=${
          TRUSTED_DOMAINS_REVERSED[window.location.origin]
        }`
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.isReady, isProtectedRoute])

  useEffect(() => {
    const domain = window.location.href.includes('localhost')
      ? process.env.NEXT_PUBLIC_DEV_CURRENT_DOMAIN
      : extractDomains(window.location.hostname).mainDomain

    if (
      router.pathname === '/404' ||
      commonRoutes.includes(router.pathname) ||
      ALL_ROUTES[domain].some((route) => router.pathname.startsWith(route))
    )
      return

    router.push('/404')
  }, [router, router.pathname])

  return (
    <>
      <DialogComponent
        title="It seems you're signed in with a different account. Please login with the same account you are using in the game."
        open={openModal}
        sx={{ zIndex: 9999999999 }}
        agreeText="Switch Account"
        handleAgree={async () => {
          setLoadingOverlay(true)
          const jwt = localStorage.getItem('jwt')
          await reqLogoutUser(jwt)
          changeHasUserStatus(false)
          setLoadingOverlay(false)
          await logoutUser(true, router.asPath)
          setOpenModal(false)
          router.reload()
        }}
        handleDisagree={() => {
          setOpenModal(false)
          router.replace({ query: '' })
        }}
      />
      <NetworkStatus />
      <GameDialog />
      {children}
    </>
  )
}

export default AppLayout
