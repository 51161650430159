import {
  AppSettingsAlt,
  BusinessSharp,
  DesktopMacOutlined,
  DeveloperBoardOutlined,
  GroupOutlined,
  MenuBookOutlined,
} from '@mui/icons-material'
import * as React from 'react'
import ROUTES from '@constant/Routes'
import { MainMenuItem } from '@type/common'
import LegalLinks from '@constant/LegalLinks'

export const RIGGED_ECONOMY_MENU_ITEMS: MainMenuItem = {
  name: 'Rigged Economy',
  path: '',
  domain: process.env.NEXT_PUBLIC_RIGGED_ECONOMY_URL,
  icon: <AppSettingsAlt />,
  newPageMenu: true,
  subItems: [
    {
      name: 'Rigged Economy',
    },
    {
      icon: <DeveloperBoardOutlined />,
      name: 'Debate',
      path: ROUTES.InternetPriorDebates,
      showSubItems: true,
      admin: true,
      subItems: [
        {
          name: 'Debate Now',
          path: ROUTES.InternetDebateNow,
        },
        {
          name: 'Prior Debates',
          path: ROUTES.InternetPriorDebates,
        },
      ],
    },
    {
      icon: <GroupOutlined />,
      name: 'Discussion Forums',
      path: ROUTES.InternetForum,
      admin: true,
    },
    {
      icon: <DesktopMacOutlined />,
      name: 'Articles',
      path: ROUTES.InternetAppsArticle,
      admin: true,
    },
    {
      icon: <BusinessSharp />,
      name: 'Rigged Undergrounds',
      path: ROUTES.RiggedEconomyRiggedAreas,
      admin: true,
    },
    {
      icon: <MenuBookOutlined />,
      name: 'Philosophy Packs',
      path: ROUTES.InternetAppsPhilosophicPack,
    },
    {
      icon: <MenuBookOutlined />,
      name: 'SlideShow Images',
      path: ROUTES.SlideshowImages,
      admin: true,
    },
  ],
}

export const riggedEconomyConfig = {
  name: 'Rigged Economy',
  logo: 'https://d37tchdccajyhz.cloudfront.net/static/rigged-hq-logo.png',
  gaCode: process.env.NEXT_PUBLIC_GTM_CODE,
  privacyPolicy: LegalLinks.PrivacyPolicy,
  marketingVideoId: 'KKTU6zDYrBw',
  marketingIntroVideoId: process.env.NEXT_PUBLIC_MARKETING_INTRO_VIDEO_ID,
  favicon: {
    32: 'riggedhq-favicon.ico',
    16: 'riggedhq-favicon.ico',
    apple: 'riggedhq-favicon.ico',
  },
  theme: {
    primary: {
      main: '#7F00FF',
      dark: '#4C096F',
    },
    buttonGradients: {
      gradientStart: '#4AA0FF',
      gradientEnd: '#025CC0',
    },
  },
  fixesMenuItems: {
    ...RIGGED_ECONOMY_MENU_ITEMS,
    subItems: RIGGED_ECONOMY_MENU_ITEMS.subItems,
  },
  homePage: 'RIGGED_ECONOMY',
  paymentMenu: false,
}

// `/**
//  * @deprecated
//  */
// export const OLD_RIGGED_ECONOMY_MENU_ITEMS: MainMenuItem = {
//   name: 'Rigged Economy',
//   path: '',
//   domain: process.env.NEXT_PUBLIC_RIGGED_ECONOMY_URL,
//   icon: <AppSettingsAlt />,
//   newPageMenu: true,
//   subItems: [
//     {
//       name: 'Rigged Economy',
//     },
//     {
//       icon: <DeveloperBoardOutlined />,
//       name: 'Debate',
//       path: ROUTES.InternetPriorDebates,
//       showSubItems: false,
//       subItems: [
//         {
//           name: 'Debate Now',
//           path: ROUTES.InternetDebateNow,
//           hideMenu: false,
//         },
//         {
//           name: 'Add debate',
//           path: ROUTES.InternetAddDebate,
//           hideMenu: true,
//         },
//         {
//           name: 'Edit debate',
//           path: ROUTES.InternetEditDebate,
//           hideMenu: true,
//         },
//         {
//           name: 'Debate',
//           path: ROUTES.InternetDebate,
//           hideMenu: true,
//         },
//         {
//           name: 'Prior Debates',
//           path: ROUTES.InternetPriorDebates,
//         },
//       ],
//     },
//     {
//       icon: <GroupOutlined />,
//       name: 'Discussion Forums',
//       path: ROUTES.InternetForum,
//       showSubItems: false,
//       subItems: [
//         {
//           name: 'Discussion Forums',
//           path: ROUTES.InternetForum,
//         },
//         {
//           name: 'Add Forum',
//           path: ROUTES.InternetAddForum,
//           hideMenu: true,
//         },
//         {
//           name: 'Edit Forum',
//           path: ROUTES.InternetEditForum,
//           hideMenu: true,
//         },
//         {
//           name: 'Edit Forum',
//           path: ROUTES.InternetForumPost,
//           hideMenu: true,
//         },
//         {
//           name: 'Edit Forum',
//           path: ROUTES.InternetEditForumPost,
//           hideMenu: true,
//         },
//       ],
//     },
//     // {
//     //   icon: <DesktopMacOutlined />,
//     //   name: 'Thought Leaders',
//     //   path: ROUTES.InternetAppsArticle,
//     // },
//     // {
//     //   icon: <DesktopMacOutlined />,
//     //   name: 'Books',
//     //   path: ROUTES.InternetAppsArticle,
//     // },
//     {
//       icon: <DesktopMacOutlined />,
//       name: 'Articles',
//       path: ROUTES.InternetAppsArticle,
//     },
//     // {
//     //   icon: <DesktopMacOutlined />,
//     //   name: 'Inflation',
//     //   path: ROUTES.InternetAppsArticle,
//     // },
//     // {
//     //   icon: <DeveloperBoardOutlined />,
//     //   name: 'Rigged Areas',
//     //   path: ROUTES.InternetAppsRiggedAreas,
//     //   showSubItems: true,
//     //   subItems: [
//     //     {
//     //       icon: <DeveloperBoardOutlined />,
//     //       name: 'Tax',
//     //       path: ROUTES.InternetAppsRiggedAreasTaxTactics,
//     //       // path: ROUTES.InternetAppsRiggedAreasTax,
//     //       hideMenu: false,
//     //       showSubItems: false,
//     //       subItems: [
//     //         {
//     //           name: 'Tax  - Rigged Home',
//     //           path: ROUTES.InternetAppsRiggedAreasTaxRiggedHome,
//     //           hideMenu: false,
//     //         },
//     //         {
//     //           name: 'Tax  - Tactics',
//     //           path: ROUTES.InternetAppsRiggedAreasTaxTactics,
//     //           hideMenu: false,
//     //         }
//     //       ]
//     //     },
//     //     {
//     //       name: 'HealthCare',
//     //       path: ROUTES.InternetDebateNow,
//     //       hideMenu: false,
//     //       subItems: [
//     //         {
//     //           name: 'HealthCare  - Rigged Home',
//     //           path: ROUTES.InternetAppsRiggedAreasTaxRiggedHome,
//     //           hideMenu: false,
//     //         },
//     //         {
//     //           name: 'HealthCare  - Tactics',
//     //           path: ROUTES.InternetAppsRiggedAreasTaxTactics,
//     //           hideMenu: false,
//     //         }
//     //       ]
//     //     },
//     //     {
//     //       name: 'Pharma',
//     //       path: ROUTES.InternetDebateNow,
//     //       hideMenu: false,
//     //       subItems: [
//     //         {
//     //           name: 'Pharma  - Rigged Home',
//     //           path: ROUTES.InternetAppsRiggedAreasTaxRiggedHome,
//     //           hideMenu: false,
//     //         },
//     //         {
//     //           name: 'Pharma  - Tactics',
//     //           path: ROUTES.InternetAppsRiggedAreasTaxTactics,
//     //           hideMenu: false,
//     //         }
//     //       ]
//     //     },{
//     //       name: 'Finance',
//     //       path: ROUTES.InternetDebateNow,
//     //       hideMenu: false,
//     //       subItems: [
//     //         {
//     //           name: 'Finance  - Rigged Home',
//     //           path: ROUTES.InternetAppsRiggedAreasTaxRiggedHome,
//     //           hideMenu: false,
//     //         },
//     //         {
//     //           name: 'Finance  - Tactics',
//     //           path: ROUTES.InternetAppsRiggedAreasTaxTactics,
//     //           hideMenu: false,
//     //         }
//     //       ]
//     //     },
//     //     // {
//     //     //   name: 'Edit debate',
//     //     //   path: ROUTES.InternetEditDebate,
//     //     //   hideMenu: true,
//     //     // },
//     //     // {
//     //     //   name: 'Debate',
//     //     //   path: ROUTES.InternetDebate,
//     //     //   hideMenu: true,
//     //     // },
//     //     // {
//     //     //   name: 'Prior Debates',
//     //     //   path: ROUTES.InternetPriorDebates,
//     //     // },
//     //   ],
//     // },
//     {
//       icon: <BusinessSharp />,
//       name: 'Rigged Undergrounds',
//       path: ROUTES.RiggedEconomyRiggedAreas,
//       showSubItems: false,
//       subItems: [
//         {
//           name: 'Rigged Undergrounds',
//           path: ROUTES.RiggedEconomyRiggedAreas,
//         },
//       ],
//     },
//     {
//       icon: <MenuBookOutlined />,
//       name: 'Philosophy Packs',
//       path: ROUTES.InternetAppsPhilosophicPack,
//     },
//     {
//       name: 'Articles',
//       path: ROUTES.InternetAppsAddArticle,
//       hideMenu: true,
//     },
//     {
//       name: 'Articles',
//       path: ROUTES.InternetAppsEditArticle,
//       hideMenu: true,
//     },
//     // {
//     //   icon: <DesktopMacOutlined />,
//     //   name: 'Your Profile',
//     //   path: ROUTES.InternetAppsEditArticle,
//     // },
//     // {
//     //   icon: <DesktopMacOutlined />,
//     //   name: 'Your Fix',
//     //   path: ROUTES.InternetAppsEditArticle,
//     // },
//     // {
//     //   icon: <DesktopMacOutlined />,
//     //   name: 'Your Family',
//     //   path: ROUTES.InternetAppsEditArticle,
//     // },
//     // {
//     //   icon: <DesktopMacOutlined />,
//     //   name: 'Their Fix',
//     //   path: ROUTES.InternetAppsEditArticle,
//     // },
//     // {
//     //   icon: <DesktopMacOutlined />,
//     //   name: 'Leaderboard',
//     //   path: ROUTES.InternetAppsEditArticle,
//     // },
//     // {
//     //   icon: <DesktopMacOutlined />,
//     //   name: 'LLM Query Queue',
//     //   path: ROUTES.InternetAppsEditArticle,
//     // },
//     // {
//     //   icon: <AppSettingsAlt />,
//     //   name: 'Rigged Apps',
//     //   path: ROUTES.RiggedEconomyApps,
//     // },
//   ],
// }`
